const brands = [
	{
		ranking: 0,
		_id: '62ff861fb392edca74ac96a7',
		brand: 'Vitra',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Vitra.png',
		clones: ['Vitra', 'VITRA', 'vitra'],
		__v: 2,
	},
	{
		ranking: 0,
		_id: '62ff861fb392edca74ac96a9',
		brand: 'World Market',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/world%20market.jpeg',
		clones: [
			'World Market',
			'WORLD MARKET',
			'world market',
			'Cost Plus World Market',
			'Cost Plus',
		],
		__v: 2,
	},
	{
		ranking: 0,
		_id: '62ff861fb392edca74ac96ab',
		brand: 'JOYBIRD',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/joybird.jpeg',
		clones: ['JOYBIRD', 'joybird', 'Joybird Hughes Twin Sleeper', 'Joybird'],
		__v: 2,
	},
	{
		_id: '62ff861fb392edca74ac96ad',
		brand: 'West Elm',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/west_elm_logo.svg',
		ranking: 1.5,
		clones: [
			'West Elm',
			'WEST ELM',
			'west elm',
			'West elm',
			'West Elm Andes Sectional',
			'West Elm ',
		],
		__v: 2,
	},
	{
		ranking: 0,
		_id: '62ff8620b392edca74ac96af',
		brand: 'Ikea',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/ikea.svg',
		clones: [
			'Ikea',
			'IKEA',
			'ikea',
			'Ikea- Kallax',
			'Ikea Vreta',
			'Ikea Brimnes',
			'IKEA',
			'IKEA ',
		],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8620b392edca74ac96b1',
		brand: 'One Kings Lane',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/one-kings-lane-logo.png',
		clones: ['One Kings Lane', 'ONE KINGS LANE', 'one kings lane', 'Lane'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8620b392edca74ac96b3',
		brand: 'Orren Ellis',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/orren%20ellis.jpeg',
		clones: ['Orren Ellis', 'ORREN ELLIS', 'orren ellis'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8620b392edca74ac96b5',
		brand: 'Gray Malin',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Gray_Malin_Logo.jpeg',
		clones: ['Gray Malin', 'GRAY MALIN', 'gray malin'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8620b392edca74ac96b7',
		brand: 'Room & Board',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/room_and_board.svg',
		relevance: 1.5,
		clones: [
			'Room & Board',
			'ROOM & BOARD',
			'room & board',
			'R&B',
			'Room and Board',
		],
		__v: 0,
	},
	{
		_id: '62ff8620b392edca74ac96b9',
		brand: 'Crate & Barrel',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/crate_and_barrel.svg',
		ranking: 1,
		clones: [
			'Crate & Barrel',
			'CRATE & BARREL',
			'crate & barrel',
			'C&B',
			'Crate And Barrel',
			'Crate and Barrel',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8620b392edca74ac96bb',
		brand: 'Wayfair',
		// COPYRIGHT VIOLATION
		// link: 'https://ik.imagekit.io/kuj9zqlrx0or/wayfair.jpeg',
		link: '',
		clones: [
			'Wayfair',
			'WAYFAIR',
			'wayfair',
			'Lystra',
			'Cecele',
			'Rosdorf Park',
			'Everly Quinn',
			'Mistana',
		],
		__v: 0,
	},
	{
		_id: '62ff8620b392edca74ac96bd',
		brand: 'Herman Miller',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/hermann_miller.svg',
		ranking: 1.5,
		clones: ['Herman Miller', 'HERMAN MILLER', 'herman miller', 'HM'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8621b392edca74ac96bf',
		brand: 'Article',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/article.svg',
		Relevance: 1.5,
		clones: ['Article', 'ARTICLE', 'article', 'Article Clarus'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8621b392edca74ac96c1',
		brand: 'Fezibo',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/fezibo.png',
		clones: ['Fezibo', 'FEZIBO', 'fezibo'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8621b392edca74ac96c3',
		brand: 'American Leather',
		link: 'https://storage.googleapis.com/kashew_brands/American%2BLeather.png',
		clones: [
			'American Leather',
			'AMERICAN LEATHER',
			'american leather',
			'American Leather - Malibu Sectional',
		],
		__v: 0,
	},
	{
		_id: '62ff8621b392edca74ac96c5',
		brand: 'Pottery Barn',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/pottery_barn.svg',
		ranking: 1,
		clones: [
			'Pottery Barn',
			'POTTERY BARN',
			'pottery barn',
			'Pottery Barn Kids',
			'Pottery Barns',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8621b392edca74ac96c7',
		brand: 'Lafer Italy',
		link: 'https://storage.googleapis.com/kashew_brands/furniture_manufacturers_lafer.jpeg',
		clones: [
			'Lafer Italy',
			'LAFER ITALY',
			'lafer italy',
			'Lafer',
			'Lafer Furniture',
		],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8621b392edca74ac96c9',
		brand: 'Four Hands',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/fourhands-logo.svg',
		clones: [
			'Four Hands',
			'FOUR HANDS',
			'four hands',
			'Four Hand',
			'Four hands',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8621b392edca74ac96cb',
		brand: 'Lee Industries',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Lee50thLogo_new.jpeg',
		clones: ['Lee Industries', 'LEE INDUSTRIES', 'lee industries'],
		__v: 0,
	},
	{
		_id: '62ff8621b392edca74ac96cd',
		brand: 'Baker Furniture',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/baker-general.jpeg',
		ranking: 1,
		clones: [
			'Baker Furniture',
			'BAKER FURNITURE',
			'baker furniture',
			'Robert Baker',
			'Baker Furniture Company',
			'Baker',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8622b392edca74ac96cf',
		brand: 'Vig Furniture',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/viglogo.png',
		clones: ['Vig Furniture', 'VIG FURNITURE', 'vig furniture', 'Vig'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8622b392edca74ac96d1',
		brand: 'Design Within Reach',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/design_within_reach.svg',
		clones: [
			'Design Within Reach',
			'DESIGN WITHIN REACH',
			'design within reach',
			'Le Corbusier',
			'Design With In Reach',
		],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8622b392edca74ac96d3',
		brand: 'Williams Sonoma',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/williams-sonoma-home-logo-vector.png',
		clones: [
			'Williams Sonoma',
			'WILLIAMS SONOMA',
			'williams sonoma',
			'Williams-Sonoma',
			'William Sonoma Home',
			'Williams Sonoma Home',
		],
		__v: 3,
	},
	{
		ranking: 0,
		_id: '62ff8622b392edca74ac96d5',
		brand: 'Knoll',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/knoll_logo_clean.png',
		relevance: '1',
		clones: ['Knoll', 'KNOLL', 'knoll', 'Knoll Omt-Veyhl'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8622b392edca74ac96d7',
		brand: 'Roche Bobois',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/roche_bobois.svg',
		clones: ['Roche Bobois', 'ROCHE BOBOIS', 'roche bobois'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8622b392edca74ac96d9',
		brand: 'Hancock & Moore',
		link: 'https://storage.googleapis.com/kashew_brands/hancock-moore-logo.png',
		clones: ['Hancock & Moore', 'HANCOCK & MOORE', 'hancock & moore'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8622b392edca74ac96db',
		brand: 'Donghia',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/donghia-logo.png',
		clones: ['Donghia', 'DONGHIA', 'donghia'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8622b392edca74ac96dd',
		brand: 'Shine by S.H.O.',
		link: 'https://storage.googleapis.com/kashew_brands/f_33283_logo.jpeg',
		clones: ['Shine by S.H.O.', 'SHINE BY S.H.O.', 'shine by s.h.o.'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8623b392edca74ac96df',
		brand: 'Ethan Allen',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/ethan_allen.svg',
		clones: ['Ethan Allen', 'ETHAN ALLEN', 'ethan allen'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8623b392edca74ac96e1',
		brand: 'Flash Furniture',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Flash-Logo.png',
		clones: ['Flash Furniture', 'FLASH FURNITURE', 'flash furniture'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8623b392edca74ac96e3',
		brand: 'The MT Company',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/logo_themtcompany.svg',
		clones: ['The MT Company', 'THE MT COMPANY', 'the mt company'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8623b392edca74ac96e5',
		brand: 'Emerald Home',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/logo-original.png',
		clones: ['Emerald Home', 'EMERALD HOME', 'emerald home'],
		__v: 0,
	},
	{
		_id: '62ff8623b392edca74ac96e7',
		brand: 'Drexel Heritage',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/drexel-heritage-logo-vector.png',
		ranking: 1,
		clones: [
			'Drexel Heritage',
			'DREXEL HERITAGE',
			'drexel heritage',
			'Heritage',
			'Drexel',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8623b392edca74ac96e9',
		brand: 'Ashley',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/ashley_furniture.svg',
		clones: [
			'Ashley',
			'ASHLEY',
			'ashley',
			'Arroyo',
			'Realyn',
			'Ashley Furniture',
			'Ashley Home Furniture',
			'Ashley ',
		],
		__v: 3,
	},
	{
		ranking: 0,
		_id: '62ff8623b392edca74ac96eb',
		brand: 'Pier One Imports',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/pier_1_imports.svg',
		clones: [
			'Pier One Imports',
			'PIER ONE IMPORTS',
			'pier one imports',
			'Pier 1 Imports',
			'Pier One',
			'Pier 1',
			'Pier One, pair of bird lamps',
		],
		__v: 3,
	},
	{
		ranking: 0,
		_id: '62ff8623b392edca74ac96ed',
		brand: 'Zuri',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/zuri.png',
		clones: ['Zuri', 'ZURI', 'zuri'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8624b392edca74ac96ef',
		brand: 'Macys',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/blt80e4fe55ff9f2890-MacysFurnitureGallery_logo.png',
		clones: [
			'Macys',
			'MACYS',
			'macys',
			"Macy's",
			'Macy’s',
			'Macy,s',
			'Macys daybed',
		],
		__v: 2,
	},
	{
		ranking: 0,
		_id: '62ff8624b392edca74ac96f1',
		brand: 'Living Spaces',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/living_spaces.svg',
		clones: ['Living Spaces', 'LIVING SPACES', 'living spaces', 'Living Space'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8624b392edca74ac96f3',
		brand: 'Kroll Furniture',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/logo.jpeg',
		clones: [
			'Kroll Furniture',
			'KROLL FURNITURE',
			'kroll furniture',
			'Kroll SF',
		],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8624b392edca74ac96f5',
		brand: 'Sam Moore',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Furniture-Logo-Sam-Moore-e1504826789584.png',
		clones: ['Sam Moore', 'SAM MOORE', 'sam moore'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8624b392edca74ac96f7',
		brand: 'Mitchell Gold + Bob Williams',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/mitchell.png',
		clones: [
			'Mitchell Gold + Bob Williams',
			'MITCHELL GOLD + BOB WILLIAMS',
			'mitchell gold + bob williams',
			'Mitchell Gold & Bob Williams',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8624b392edca74ac96f9',
		brand: 'Normann Copenhagen',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/1_JLnHvXZJSmN70flMgQy1XQ.png',
		clones: [
			'Normann Copenhagen',
			'NORMANN COPENHAGEN',
			'normann copenhagen',
			'Copenhagen',
			'Copehagen',
			'Copehagen / Crate & Barrel',
		],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8624b392edca74ac96fb',
		brand: 'Modernica',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/modernica-logo_cb8a7fa7-76d3-426f-b72f-da358e2dce75_650x.png',
		clones: ['Modernica', 'MODERNICA', 'modernica'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8624b392edca74ac96fd',
		brand: 'Patricia Edwards',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/patricia.jpeg',
		clones: ['Patricia Edwards', 'PATRICIA EDWARDS', 'patricia edwards'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8624b392edca74ac96ff',
		brand: 'Blu Dot',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/blu_dot.svg',
		clones: ['Blu Dot', 'BLU DOT', 'blu dot'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8625b392edca74ac9701',
		brand: 'Allermuir',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/allermuir.jpeg',
		clones: ['Allermuir', 'ALLERMUIR', 'allermuir'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8625b392edca74ac9703',
		brand: 'Restoration Hardware',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/restoration_hardware.svg',
		relevance: 1.5,
		clones: [
			'Restoration Hardware',
			'RESTORATION HARDWARE',
			'restoration hardware',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8625b392edca74ac9705',
		brand: 'Galaxy Home',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/galaxyhome.png',
		clones: ['Galaxy Home', 'GALAXY HOME', 'galaxy home'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8625b392edca74ac9707',
		brand: 'Suite America',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/SA-Logo.png',
		clones: ['Suite America', 'SUITE AMERICA', 'suite america'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8625b392edca74ac9709',
		brand: 'Hon',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/horizontal_logo.svg',
		clones: ['Hon', 'HON', 'hon'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8625b392edca74ac970b',
		brand: 'CORT',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/cort_logo.svg',
		clones: ['CORT', 'cort'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8625b392edca74ac970d',
		brand: 'Bassett Mirror Co.',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/bassett-mirror-company-logo.jpeg',
		clones: [
			'Bassett Mirror Co.',
			'BASSETT MIRROR CO.',
			'bassett mirror co.',
			'Bassett',
			'Basset Furniture',
		],
		__v: 2,
	},
	{
		ranking: 0,
		_id: '62ff8625b392edca74ac970f',
		brand: 'Bed Bath & Beyond',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/BBB.png',
		clones: ['Bed Bath & Beyond', 'BED BATH & BEYOND', 'bed bath & beyond'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8626b392edca74ac9711',
		brand: 'Rejuvenation',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Rejuvenation_(lighting_and_hardware)_logo.png',
		clones: ['Rejuvenation', 'REJUVENATION', 'rejuvenation'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8626b392edca74ac9713',
		brand: 'Kreiss',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/kreiss.jpeg',
		clones: ['Kreiss', 'KREISS', 'kreiss'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8626b392edca74ac9715',
		brand: 'Ligna Furniture',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/ligna-logo.png',
		clones: ['Ligna Furniture', 'LIGNA FURNITURE', 'ligna furniture'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8626b392edca74ac9717',
		brand: 'Thomasville',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/thomasville.svg',
		clones: ['Thomasville', 'THOMASVILLE', 'thomasville'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8626b392edca74ac9719',
		brand: 'Francesco Molon',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/logo-molon.png',
		clones: ['Francesco Molon', 'FRANCESCO MOLON', 'francesco molon'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8626b392edca74ac971b',
		brand: "Kirkland's",
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Kirklands_black.jpeg',
		clones: ["Kirkland's", "KIRKLAND'S", "kirkland's", 'Kirklands'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8626b392edca74ac971d',
		brand: 'Vioski',
		link: 'https://storage.googleapis.com/kashew_brands/VIOSKI-logo%2B-%2BPNG%2B3.png',
		clones: ['Vioski', 'VIOSKI', 'vioski'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8626b392edca74ac971f',
		brand: 'Quatrine',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/logo-quatrine-1_302x90.png',
		clones: ['Quatrine', 'QUATRINE', 'quatrine'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8627b392edca74ac9721',
		brand: 'Biltmore',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/biltmore-logo.png',
		clones: ['Biltmore', 'BILTMORE', 'biltmore'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8627b392edca74ac9723',
		brand: 'Dir',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/dirlog.png',
		clones: ['Dir', 'DIR', 'dir', 'Dream In Reality'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8627b392edca74ac9725',
		brand: 'Bauhaus',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/bauhaus.jpeg',
		clones: ['Bauhaus', 'BAUHAUS', 'bauhaus'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8627b392edca74ac9727',
		brand: 'Studio Lorier',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Logo-Studio-Lorier.jpeg',
		clones: ['Studio Lorier', 'STUDIO LORIER', 'studio lorier'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8627b392edca74ac9729',
		brand: 'AICO',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/AicoWeb.png',
		clones: ['AICO', 'aico', 'Michael Amini'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8627b392edca74ac972b',
		brand: 'Precedent',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/precedent-logo.png',
		clones: ['Precedent', 'PRECEDENT', 'precedent'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8627b392edca74ac972d',
		brand: 'Giorgio USA',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/giorgio.png',
		clones: ['Giorgio USA', 'GIORGIO USA', 'giorgio usa'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8627b392edca74ac972f',
		brand: 'Skovby',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/skovby-logo2.svg',
		clones: ['Skovby', 'SKOVBY', 'skovby'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8628b392edca74ac9731',
		brand: 'Palliser Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/palliser-furniture-vector-logo.png',
		clones: [
			'Palliser Furniture',
			'PALLISER FURNITURE',
			'palliser furniture',
			'Palliser',
			'PALLISER',
			'palliser',
		],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8628b392edca74ac9733',
		brand: 'Maddox Co.',
		link: '',
		clones: ['Maddox Co.', 'MADDOX CO.', 'maddox co.', 'Maddox Table Co.'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8628b392edca74ac9735',
		brand: 'Karges',
		link: 'https://storage.googleapis.com/kashew_brands/Karges_NewLogo_grey.png',
		clones: ['Karges', 'KARGES', 'karges', 'Karges Furniture'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8628b392edca74ac9737',
		brand: 'Henredon',
		link: 'https://storage.googleapis.com/kashew_brands/Henredon.jpeg',
		clones: ['Henredon', 'HENREDON', 'henredon'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8628b392edca74ac9739',
		brand: 'Broyhill',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(1).png',
		clones: ['Broyhill', 'BROYHILL', 'broyhill'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8628b392edca74ac973b',
		brand: 'Ralph Lauren',
		link: 'https://storage.googleapis.com/kashew_brands/ralph-lauren.png',
		clones: [
			'Ralph Lauren',
			'RALPH LAUREN',
			'ralph lauren',
			'Ralph Lauren For Henredon',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8628b392edca74ac973d',
		brand: 'Lark Manor',
		link: 'https://storage.googleapis.com/kashew_brands/default_name.webp',
		clones: ['Lark Manor', 'LARK MANOR', 'lark manor', 'Lark Manor Wayfair'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8628b392edca74ac973f',
		brand: 'Jonathan Adler',
		link: 'https://storage.googleapis.com/kashew_brands/Jonathan-Adler-logo.jpeg',
		clones: ['Jonathan Adler', 'JONATHAN ADLER', 'jonathan adler'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8629b392edca74ac9741',
		brand: 'EQ3',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(2).png',
		clones: ['EQ3', 'eq3'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8629b392edca74ac9743',
		brand: 'Woodbridge Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/woodbridge-furniture-logo.jpeg',
		clones: [
			'Woodbridge Furniture',
			'WOODBRIDGE FURNITURE',
			'woodbridge furniture',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8629b392edca74ac9745',
		brand: 'Interior Define',
		link: 'https://storage.googleapis.com/kashew_brands/id-logo.png',
		clones: ['Interior Define', 'INTERIOR DEFINE', 'interior define'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8629b392edca74ac9747',
		brand: 'Scandinavian Designs',
		link: 'https://storage.googleapis.com/kashew_brands/scandinavian-designs-logo.png',
		clones: [
			'Scandinavian Designs',
			'SCANDINAVIAN DESIGNS',
			'scandinavian designs',
			'Scandinavian Design',
		],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff8629b392edca74ac9749',
		brand: 'Stanford Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/stanford.png',
		clones: ['Stanford Furniture', 'STANFORD FURNITURE', 'stanford furniture'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8629b392edca74ac974b',
		brand: 'ICA Home Decor',
		link: 'https://storage.googleapis.com/kashew_brands/10.gif.opdownload',
		clones: ['ICA Home Decor', 'ICA HOME DECOR', 'ica home decor'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8629b392edca74ac974d',
		brand: 'American Drew',
		link: 'https://storage.googleapis.com/kashew_brands/American_Drew_Logo.gif',
		clones: ['American Drew', 'AMERICAN DREW', 'american drew'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff8629b392edca74ac974f',
		brand: 'Bernhardt',
		link: 'https://storage.googleapis.com/kashew_brands/20565.jpeg',
		clones: ['Bernhardt', 'BERNHARDT', 'bernhardt', 'Bernhardt Furniture'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff862ab392edca74ac9751',
		brand: 'Vaughan-Bassett',
		link: 'https://storage.googleapis.com/kashew_brands/logo.png',
		clones: ['Vaughan-Bassett', 'VAUGHAN-BASSETT', 'vaughan-bassett'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862ab392edca74ac9753',
		brand: 'Stylus',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(3).jpeg',
		clones: ['Stylus', 'STYLUS', 'stylus'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862ab392edca74ac9755',
		brand: 'South Shore Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/South%2BShore.webp',
		clones: [
			'South Shore Furniture',
			'SOUTH SHORE FURNITURE',
			'south shore furniture',
			'South Shore',
		],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff862ab392edca74ac9757',
		brand: 'Bassett Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/bassett-furniture.png',
		clones: [
			'Bassett Furniture',
			'BASSETT FURNITURE',
			'bassett furniture',
			'Basset',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862ab392edca74ac9759',
		brand: 'Salamander Designs',
		link: 'https://storage.googleapis.com/kashew_brands/salamander-logo.jpeg',
		clones: [
			'Salamander Designs',
			'SALAMANDER DESIGNS',
			'salamander designs',
			'Salamander',
		],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff862ab392edca74ac975b',
		brand: 'OFM',
		link: 'https://storage.googleapis.com/kashew_brands/1280px-OFM_logo.svg.png',
		clones: ['OFM', 'ofm'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862ab392edca74ac975d',
		brand: 'Italsofa',
		link: 'https://storage.googleapis.com/kashew_brands/logo-italsofa.webp',
		clones: ['Italsofa', 'ITALSOFA', 'italsofa', 'Natuzzi/Italsofa', 'Natuzzi'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862ab392edca74ac975f',
		brand: 'Klaussner',
		link: 'https://storage.googleapis.com/kashew_brands/klaussner-logo-horizontal.jpeg',
		clones: ['Klaussner', 'KLAUSSNER', 'klaussner'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862bb392edca74ac9761',
		brand: 'Philip Reinisch Company',
		link: 'https://storage.googleapis.com/kashew_brands/Philip%2BReinisch%2BCo..webp',
		clones: [
			'Philip Reinisch Company',
			'PHILIP REINISCH COMPANY',
			'philip reinisch company',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862bb392edca74ac9763',
		brand: 'Modani',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(4).png',
		clones: ['Modani', 'MODANI', 'modani'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862bb392edca74ac9765',
		brand: 'New Pacific Direct',
		link: 'https://storage.googleapis.com/kashew_brands/new-pacific-direct-squarelogo-1448290122952.png',
		clones: ['New Pacific Direct', 'NEW PACIFIC DIRECT', 'new pacific direct'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862bb392edca74ac9767',
		brand: 'Gaylord Soli',
		link: '',
		clones: ['Gaylord Soli', 'GAYLORD SOLI', 'gaylord soli'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862bb392edca74ac9769',
		brand: 'Custom',
		link: '',
		clones: ['Custom', 'CUSTOM', 'custom'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862bb392edca74ac976b',
		brand: 'Michael Thomas',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(5).png',
		clones: ['Michael Thomas', 'MICHAEL THOMAS', 'michael thomas'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862bb392edca74ac976d',
		brand: 'Masland',
		link: 'https://storage.googleapis.com/kashew_brands/masland_carpets_rugs_image.jpeg',
		clones: ['Masland', 'MASLAND', 'masland'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862bb392edca74ac976f',
		brand: 'Universal Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(6).png',
		clones: [
			'Universal Furniture',
			'UNIVERSAL FURNITURE',
			'universal furniture',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862cb392edca74ac9771',
		brand: 'Home Decorators Collection',
		link: 'https://storage.googleapis.com/kashew_brands/g4Jvv91RCapgyaGMfATPw6cbTgx1567084138768_200x200.png',
		clones: [
			'Home Decorators Collection',
			'HOME DECORATORS COLLECTION',
			'home decorators collection',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862cb392edca74ac9773',
		brand: 'OFS',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(7).png',
		clones: ['OFS', 'ofs'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862cb392edca74ac9775',
		brand: 'John Stuart',
		link: '',
		clones: ['John Stuart', 'JOHN STUART', 'john stuart'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862cb392edca74ac9777',
		brand: 'CB2',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/cb2.svg',
		relevance: 1.5,
		clones: ['CB2', 'cb2'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862cb392edca74ac9779',
		brand: 'Glostrup Mobelfabrik',
		link: '',
		clones: [
			'Glostrup Mobelfabrik',
			'GLOSTRUP MOBELFABRIK',
			'glostrup mobelfabrik',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862cb392edca74ac977b',
		brand: 'Friant',
		link: 'https://storage.googleapis.com/kashew_brands/logo2.jpeg',
		clones: ['Friant', 'FRIANT', 'friant'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862cb392edca74ac977d',
		brand: 'Aaron Brothers',
		link: 'https://storage.googleapis.com/kashew_brands/smywpdawqbef44tdtemw.webp',
		clones: ['Aaron Brothers', 'AARON BROTHERS', 'aaron brothers'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862cb392edca74ac977f',
		brand: 'Legacy Classic Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/Legacy_Classic_Modern_Logo2022.jpeg',
		clones: [
			'Legacy Classic Furniture',
			'LEGACY CLASSIC FURNITURE',
			'legacy classic furniture',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862db392edca74ac9781',
		brand: 'Eric Brand',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(8).png',
		clones: ['Eric Brand', 'ERIC BRAND', 'eric brand'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862db392edca74ac9783',
		brand: 'Flexsteel',
		link: 'https://storage.googleapis.com/kashew_brands/MumsFurniture_BrandLogos_Flexsteel-2-300x157.png',
		clones: ['Flexsteel', 'FLEXSTEEL', 'flexsteel'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862db392edca74ac9785',
		brand: 'Uniek Living',
		link: 'https://storage.googleapis.com/kashew_brands/UniekLiving.logo.ring.ORANGE.600-1_copy_ea8cf3d6-8cd5-43df-8fc3-7fc86f99860e.webp',
		clones: ['Uniek', 'UNIEK', 'uniek', 'Uniek Living'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff862db392edca74ac9787',
		brand: 'Ballard Designs',
		link: 'https://storage.googleapis.com/kashew_brands/Ballard%20Design%20Logo_v1.png',
		clones: ['Ballard Designs', 'BALLARD DESIGNS', 'ballard designs'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862db392edca74ac9789',
		brand: 'Molo Design',
		link: 'https://storage.googleapis.com/kashew_brands/logo-molo.png',
		clones: ['Molo Design', 'MOLO DESIGN', 'molo design'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862db392edca74ac978b',
		brand: 'Jaipur Rugs',
		link: 'https://storage.googleapis.com/kashew_brands/7799877b09c9b8f190a3cb8c4201db59.jpeg',
		clones: ['Carpet Jaipur', 'CARPET JAIPUR', 'carpet jaipur', 'Jaipur Rugs'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff862db392edca74ac978d',
		brand: 'Kimball',
		link: 'https://storage.googleapis.com/kashew_brands/4x4-e1518021716406.png',
		clones: ['Kimball', 'KIMBALL', 'kimball'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862db392edca74ac978f',
		brand: 'Oriental Weavers Of America',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(9).png',
		clones: [
			'Oriental Weavers Of America',
			'ORIENTAL WEAVERS OF AMERICA',
			'oriental weavers of america',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862eb392edca74ac9791',
		brand: 'Beacon Hill',
		link: '',
		clones: [
			'Beacon Hill',
			'BEACON HILL',
			'beacon hill',
			'Avery Boardman For Beacon Hill',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862eb392edca74ac9793',
		brand: 'Craftmaster Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/1522456844770.jpeg',
		clones: [
			'Craftmaster Furniture',
			'CRAFTMASTER FURNITURE',
			'craftmaster furniture',
			'Craftmaster North Carolina',
		],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff862eb392edca74ac9795',
		brand: 'Foundry Select',
		link: 'https://storage.googleapis.com/kashew_brands/default_name%20(1).webp',
		clones: ['Foundry Select', 'FOUNDRY SELECT', 'foundry select'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862eb392edca74ac9797',
		brand: 'Haute House',
		link: 'https://storage.googleapis.com/kashew_brands/logohh.jpeg',
		clones: ['Haute House', 'HAUTE HOUSE', 'haute house'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862eb392edca74ac9799',
		brand: 'Southern Furniture Company',
		link: 'https://storage.googleapis.com/kashew_brands/1519910761832.jpeg',
		clones: [
			'Southern Furniture Company',
			'SOUTHERN FURNITURE COMPANY',
			'southern furniture company',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862eb392edca74ac979b',
		brand: 'Coaster',
		link: 'https://storage.googleapis.com/kashew_brands/coa-logo-color.png',
		clones: ['Coaster', 'COASTER', 'coaster'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862eb392edca74ac979d',
		brand: 'Turnstone By Steelcase',
		link: 'https://storage.googleapis.com/kashew_brands/img-logo-turnstone.png',
		clones: [
			'Turnstone By Steelcase',
			'TURNSTONE BY STEELCASE',
			'turnstone by steelcase',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862eb392edca74ac979f',
		brand: 'Labrazel',
		link: 'https://storage.googleapis.com/kashew_brands/ev6z8kz2p8zhgzh78yiu.webp',
		clones: ['Labrazel', 'LABRAZEL', 'labrazel'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862eb392edca74ac97a1',
		brand: 'Waterworks',
		link: 'https://storage.googleapis.com/kashew_brands/176370.svg',
		clones: ['Waterworks', 'WATERWORKS', 'waterworks'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862fb392edca74ac97a3',
		brand: 'La-Z-Boy',
		link: 'https://storage.googleapis.com/kashew_brands/1200px-La-Z-Boy_Logo.svg.png',
		clones: ['La-Z-Boy', 'LA-Z-BOY', 'la-z-boy', 'La-Z Boy'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '62ff862fb392edca74ac97a5',
		brand: 'SmugDesk',
		link: 'https://storage.googleapis.com/kashew_brands/9a2279c558715777016eac127e8f50c2.w800.h800.jpg',
		clones: ['SmugDesk', 'SMUGDESK', 'smugdesk'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862fb392edca74ac97a7',
		brand: 'HD Buttercup',
		link: 'https://storage.googleapis.com/kashew_brands/logo_black.webp',
		clones: ['HD Buttercup', 'HD BUTTERCUP', 'hd buttercup'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862fb392edca74ac97a9',
		brand: 'Sonder Living',
		link: 'https://storage.googleapis.com/kashew_brands/SonderLiving-Logo_597x162.webp',
		clones: ['Sonder Living', 'SONDER LIVING', 'sonder living'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ff862fb392edca74ac97ab',
		brand: 'Kenmore',
		link: 'https://storage.googleapis.com/kashew_brands/kenmore-logo.jpeg',
		clones: ['Kenmore', 'KENMORE', 'kenmore'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '62ffc473e27e715f5cdff7e2',
		brand: 'Kashew',
		link: 'https://www.kashew.org/static/media/LogoDarkGreen.75930a43.svg',
		clones: ['KASHEW', 'Kashew', 'kashew'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63033973c2059a779c7602fa',
		brand: 'Jonathan Louis',
		link: 'https://storage.googleapis.com/kashew_brands/618c27_d5f72a779b9045cc8c6a3f6176a4e7bf_mv2.png',
		clones: ['Jonathan Louis', 'JONATHAN LOUIS', 'jonathan louis'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63034ce382463ca4feea16a0',
		brand: 'Z Gallerie',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(10).png',
		clones: [
			'Z Gallerie',
			'Z GALLERIE',
			'z gallerie',
			'ZGallerie',
			'Z Gallery',
		],
		__v: 2,
	},
	{
		ranking: 0,
		_id: '63034d2d82463ca4feea16a3',
		brand: 'Progressive Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/progressive_furniture_logo_2.jpeg',
		clones: [
			'Progressive Furniture',
			'PROGRESSIVE FURNITURE',
			'progressive furniture',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63034d6582463ca4feea16a6',
		brand: 'Cane-line',
		link: 'https://storage.googleapis.com/kashew_brands/yYlNOXog_400x400.jpeg',
		clones: ['Cane-line', 'Cane line', 'Cane Line', 'CANE-LINE', 'cane-line'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63034d9a82463ca4feea16a9',
		brand: 'Serena And Lily',
		link: 'https://storage.googleapis.com/kashew_brands/200114_Kilroy_SD_OnePaseo_SerenaandLily-Logo.png',
		clones: ['Serena And Lily', 'SERENA AND LILY', 'serena and lily'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63034ddf82463ca4feea16ac',
		brand: 'Safar Interiors',
		link: 'https://storage.googleapis.com/kashew_brands/logo_bordeaux_safar.webp',
		clones: ['Safar Interiors', 'SAFAR INTERIORS', 'safar interiors', 'Safar'],
		__v: 1,
	},
	{
		ranking: 0,
		_id: '63034e1f82463ca4feea16b2',
		brand: 'Eurostyle',
		link: 'https://storage.googleapis.com/kashew_brands/euro-style-furniture-vector-logo.png',
		clones: ['Eurostyle', 'EUROSTYLE', 'eurostyle'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6303518c82463ca4feea16d4',
		brand: 'Heller',
		link: 'https://storage.googleapis.com/kashew_brands/logo_1_612x.png',
		clones: ['Heller', 'HELLER', 'heller'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630351b582463ca4feea16d7',
		brand: 'Rene Cazares',
		link: 'https://storage.googleapis.com/kashew_brands/exhibitorlogo_512_66251_20161121154600-RCFN-0018_ReneCazares_Logo_1cs.jpeg',
		clones: ['Rene Cazares', 'RENE CAZARES', 'rene cazares'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6303520482463ca4feea16dd',
		brand: 'Milling Road',
		link: 'https://storage.googleapis.com/kashew_brands/Milling-Road-1.png',
		clones: [
			'Milling Road Baker Furniture',
			'Milling Road',
			'MILLING ROAD',
			'milling road',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6303524682463ca4feea16e3',
		brand: 'BoConcept',
		link: 'https://storage.googleapis.com/kashew_brands/533292_10000.jpeg',
		clones: ['BoConcept', 'BOCONCEPT', 'boconcept'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6303526b82463ca4feea16e6',
		brand: 'SEI Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/cropped-SEI-Furniture-Logo.png',
		clones: ['SEI Furniture', 'SEI FURNITURE', 'sei furniture'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6303540a82463ca4feea16f5',
		brand: 'Eames',
		link: 'https://storage.googleapis.com/kashew_brands/14925234_1326427367376372_7927995444861159234_n.jpeg',
		relevance: 1,
		clones: [
			'Eames',
			'Eames Furniture',
			'Eames Office',
			'Charles Eames',
			'Charles Eames for Herman Miller',
			'EAMES',
			'eames',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6303547682463ca4feea16f8',
		brand: 'OAH Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/52536800_254514525451160_3622485596242444288_n.jpeg',
		clones: ['OAH Furniture', 'OAH FURNITURE', 'oah furniture'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630354fb82463ca4feea16fb',
		brand: 'Burton James',
		link: 'https://storage.googleapis.com/kashew_brands/burton-james.jpeg',
		clones: ['Burton James', 'BURTON JAMES', 'burton james'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6303552882463ca4feea16fe',
		brand: 'Birch Lane',
		link: 'https://storage.googleapis.com/kashew_brands/Birch%2BLane%E2%84%A2.webp',
		clones: ['Birch Lane', 'BIRCH LANE', 'birch lane'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630355a682463ca4feea1701',
		brand: 'Land of Nod',
		link: 'https://storage.googleapis.com/kashew_brands/205-2053256_land-of-nod-logo.png',
		clones: ['Land of Nod', 'Crate&Kids', 'LAND OF NOD', 'land of nod'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630355d482463ca4feea1704',
		brand: 'Century Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/220034-century-furniture-logo-1024x432.jpeg',
		clones: [
			'Century Furniture',
			'Century',
			'CENTURY FURNITURE',
			'century furniture',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630355f982463ca4feea1707',
		brand: 'Autonomous',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/autonomous.svg',
		clones: ['Autonomous', 'AUTONOMOUS', 'autonomous'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6303561e82463ca4feea170a',
		brand: 'Sika Design',
		link: 'https://storage.googleapis.com/kashew_brands/sika-logo.jpeg',
		clones: ['Sika Design', 'SIKA DESIGN', 'sika design'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6303567d82463ca4feea170d',
		brand: 'Target',
		link: 'https://storage.googleapis.com/kashew_brands/target_logo_email.png',
		clones: ['Target', 'TARGET', 'target'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304e36ae483d9031a03e47d',
		brand: 'Homelegance',
		link: 'https://storage.googleapis.com/kashew_brands/asdasda.webp',
		clones: ['Homelegance', 'HOMELEGANCE', 'homelegance'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304e3dce483d9031a03e480',
		brand: 'DAGONHIL',
		link: 'https://storage.googleapis.com/kashew_brands/dbfcfa9a-1dab-4ae5-95ce-5ba5dfdbfa13.__CR0%2C0%2C600%2C180_PT0_SX600_V1___.jpg',
		clones: ['DAGONHIL', 'dagonhil'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304e440e483d9031a03e489',
		brand: 'Kincaid',
		link: 'https://storage.googleapis.com/kashew_brands/kincaid.jpeg',
		clones: ['Kincaid', 'KINCAID', 'kincaid'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304e4cfe483d9031a03e48c',
		brand: 'BH',
		link: 'https://storage.googleapis.com/kashew_brands/bh-fitness-logo.png',
		clones: ['BH', 'BH Fitness', 'bh'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304e526e483d9031a03e48f',
		brand: 'Schwinn',
		link: 'https://storage.googleapis.com/kashew_brands/MicrosoftTeams-image_27.webp',
		clones: ['Schwinn', 'SCHWINN', 'schwinn'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304e66be483d9031a03e492',
		brand: 'Wellrun',
		link: 'https://storage.googleapis.com/kashew_brands/Screen%20Shot%202022-08-23%20at%204.37.55%20PM.png',
		clones: ['Wellrun', 'WELLRUN', 'wellrun'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304e718e483d9031a03e495',
		brand: 'Soozier',
		link: 'https://storage.googleapis.com/kashew_brands/Screen%20Shot%202022-08-23%20at%204.40.34%20PM.png',
		clones: ['Soosier', 'Soozier', 'SOOZIER', 'soozier'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304e74de483d9031a03e498',
		brand: 'Octane Fitness',
		link: 'https://storage.googleapis.com/kashew_brands/OctaneFitnessLogo2.png',
		clones: ['Octane Fitness', 'OCTANE FITNESS', 'octane fitness'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304e9d2e483d9031a03e49b',
		brand: 'Dixie',
		link: 'https://storage.googleapis.com/kashew_brands/DixieFurnitureCompanyLogo.jpeg',
		clones: [
			'Dixie 1950',
			'Dixie',
			'Dixie furniture company',
			'DIXIE',
			'dixie',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304ea5fe483d9031a03e4a1',
		brand: 'Peloton',
		link: 'https://storage.googleapis.com/kashew_brands/Peloton_(Unternehmen)_logo.svg',
		clones: ['Peloton', 'PELOTON', 'peloton'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304ebfae483d9031a03e4a4',
		brand: 'Precor',
		link: 'https://storage.googleapis.com/kashew_brands/Precor-logo-LRG.png',
		clones: ['Precor', 'PRECOR', 'precor'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304ecabe483d9031a03e4a7',
		brand: 'Artemide',
		link: 'https://storage.googleapis.com/kashew_brands/Artemide-Logo_20cm_CMYK_864a16ad-c18e-46e4-9897-b235ab5cc40d.webp',
		clones: ['Artemide', 'ARTEMIDE', 'artemide'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304ee18e483d9031a03e4aa',
		brand: 'Gandia Blasco',
		link: 'https://storage.googleapis.com/kashew_brands/adasda.png',
		clones: ['Gandia Blasco', 'GANDIA BLASCO', 'gandia blasco'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304ef34e483d9031a03e4ad',
		brand: 'NordicTrack',
		link: 'https://storage.googleapis.com/kashew_brands/nordictrack.svg',
		clones: ['NordicTrack', 'NORDICTRACK', 'nordictrack'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304efb5e483d9031a03e4b0',
		brand: 'Stamina',
		link: 'https://storage.googleapis.com/kashew_brands/Screen%20Shot%202022-08-23%20at%205.17.36%20PM.png',
		clones: ['Stamina', 'BodyTrac', 'STAMINA', 'stamina'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304f011e483d9031a03e4b3',
		brand: 'Diamondback',
		link: 'https://storage.googleapis.com/kashew_brands/download%20(1)asdad.png',
		clones: ['Diamondback', 'DIAMONDBACK', 'diamondback'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304f061e483d9031a03e4b9',
		brand: 'Specialized',
		link: 'https://storage.googleapis.com/kashew_brands/specialized-bicycle-components-logo-vector.png',
		clones: ['Specialized', 'SPECIALIZED', 'specialized'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304f121e483d9031a03e4bf',
		brand: 'Stanley',
		link: 'https://storage.googleapis.com/kashew_brands/Stanley%2BFurniture.jpeg',
		clones: ['Stanley', 'STANLEY', 'stanley'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304f1ace483d9031a03e4c2',
		brand: 'COCOCO Home',
		link: 'https://storage.googleapis.com/kashew_brands/FAB2CwHi_400x400.jpeg',
		clones: ['COCOCO Home', 'COCOCO', 'COCOCO HOME', 'cococo home'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304f1e6e483d9031a03e4c5',
		brand: 'Tiffany & Co.',
		link: 'https://storage.googleapis.com/kashew_brands/Tiffany_%26_Co._wordmark_2003.svg.png',
		clones: ['Tiffany & Co.', 'TIFFANY & CO.', 'tiffany & co.'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304f249e483d9031a03e4ce',
		brand: 'Furniture of America',
		link: 'https://storage.googleapis.com/kashew_brands/a67ca368e95d8498530fa97c9cd2bc08.w3000.h3000.jpg',
		clones: [
			'Furniture of America',
			'Furniture Of America',
			'FURNITURE OF AMERICA',
			'furniture of america',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304f2d4e483d9031a03e4d4',
		brand: 'B&B Italia',
		link: 'https://storage.googleapis.com/kashew_brands/bandb.png',
		clones: ['B&B Italia', 'B&B ITALIA', 'b&b italia'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304f31ce483d9031a03e4d7',
		brand: 'Homey Design',
		link: 'https://storage.googleapis.com/kashew_brands/homey_logo_full-size.png',
		clones: [
			'Homey Design',
			'Homey Design Furniture',
			'HOMEY DESIGN',
			'homey design',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6304f391e483d9031a03e4e3',
		brand: 'Casper',
		link: 'https://storage.googleapis.com/kashew_brands/Casper_Sleep_logo.svg.png',
		clones: ['Casper', 'CASPER', 'casper'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306097b27719fbd06fa57d4',
		brand: 'Apple',
		link: 'https://storage.googleapis.com/kashew_brands/Apple_logo_black.svg.png',
		clones: ['Apple', 'APPLE', 'apple'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630609a127719fbd06fa57d7',
		brand: 'Bosch',
		link: 'https://storage.googleapis.com/kashew_brands/bosch-brand-bosch_res_1280x720.png',
		clones: ['Bosch', 'BOSCH', 'bosch'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630609c827719fbd06fa57da',
		brand: 'Cisco',
		link: 'https://storage.googleapis.com/kashew_brands/2560px-Cisco_logo_blue_2016.svg.png',
		clones: ['Cisco', 'CISCO', 'cisco'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630609f227719fbd06fa57dd',
		brand: 'Bose',
		link: 'https://storage.googleapis.com/kashew_brands/Bose_logo.svg.png',
		clones: ['Bose', 'BOSE', 'bose'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63060a1727719fbd06fa57e0',
		brand: 'Asus',
		link: 'https://storage.googleapis.com/kashew_brands/ASUS_Logo.svg.png',
		clones: ['Asus', 'ASUS', 'asus'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63060a5b27719fbd06fa57e6',
		brand: 'Arhaus Furniture',
		link: 'https://storage.googleapis.com/kashew_brands/ArhausLogo.png',
		clones: [
			'Arhaus Furniture',
			'Arhaus',
			'ARHAUS FURNITURE',
			'arhaus furniture',
		],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63060a9027719fbd06fa57e9',
		brand: 'Google',
		link: 'https://storage.googleapis.com/kashew_brands/Google_2015_logo.svg.png',
		clones: ['Google', 'GOOGLE', 'google'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63060ac627719fbd06fa57ec',
		brand: 'LG',
		link: 'https://storage.googleapis.com/kashew_brands/2560px-LG_logo_(2015).svg.png',
		clones: ['LG', 'lg'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63060aed27719fbd06fa57ef',
		brand: 'HP',
		link: 'https://storage.googleapis.com/kashew_brands/2048px-HP_logo_2012.svg.png',
		clones: ['HP', 'hp'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63060b1527719fbd06fa57f2',
		brand: 'Logitech',
		link: 'https://storage.googleapis.com/kashew_brands/Logitech_logo.svg.png',
		clones: ['Logitech', 'LOGITECH', 'logitech'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '63060b3e27719fbd06fa57f5',
		brand: 'Nintendo',
		link: 'https://storage.googleapis.com/kashew_brands/Nintendo_red_logo.svg.png',
		clones: ['Nintendo', 'NINTENDO', 'nintendo'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306428630aa77be920f6019',
		brand: 'A. Rudin',
		link: 'https://storage.googleapis.com/kashew_brands/rna2mh4neywbzddbinef.webp',
		clones: ['A. Rudin', 'A. RUDIN', 'a. rudin'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630642c130aa77be920f601c',
		brand: 'Abbyson',
		link: 'https://storage.googleapis.com/kashew_brands/AbbysonLogo_400x175_8a47bfed-d449-448f-b459-26199b794af9_145x%402x.webp',
		clones: ['Abbyson', 'ABBYSON', 'abbyson'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630642f030aa77be920f6022',
		brand: 'AllModern',
		link: 'https://storage.googleapis.com/kashew_brands/allmodern.jpeg',
		clones: ['AllModern', 'ALLMODERN', 'allmodern'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306433e30aa77be920f6025',
		brand: 'Anthropologie',
		link: 'https://storage.googleapis.com/kashew_brands/Anthropologie-logo.jpeg',
		clones: ['Anthropologie', 'ANTHROPOLOGIE', 'anthropologie'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306436830aa77be920f6028',
		brand: 'Baxton Studio',
		link: 'https://storage.googleapis.com/kashew_brands/Baxton-Studio-web-Black-Logo-TM__.png.opdownload',
		clones: ['Baxton Studio', 'BAXTON STUDIO', 'baxton studio'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630643a130aa77be920f603b',
		brand: 'BDI',
		link: 'https://storage.googleapis.com/kashew_brands/BDi.webp',
		clones: ['BDI', 'bdi'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630643db30aa77be920f603e',
		brand: 'Breville',
		link: 'https://storage.googleapis.com/kashew_brands/breville_logo_transparent.png',
		clones: ['Breville', 'BREVILLE', 'breville'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306441930aa77be920f6049',
		brand: 'Brownstone',
		link: 'https://storage.googleapis.com/kashew_brands/123.png',
		clones: ['Brownstone', 'BROWNSTONE', 'brownstone'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306446330aa77be920f6054',
		brand: 'Casana',
		link: 'https://storage.googleapis.com/kashew_brands/casana.jpeg',
		clones: ['Casana', 'CASANA', 'casana'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306448e30aa77be920f6057',
		brand: 'Coalesse',
		link: 'https://storage.googleapis.com/kashew_brands/11698.jpeg',
		clones: ['Coalesse', 'COALESSE', 'coalesse'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306452f30aa77be920f6072',
		brand: 'Dunbar',
		link: 'https://storage.googleapis.com/kashew_brands/8a137fdb0787c9a5_6775-w173-h173-b0-p0--_.webp',
		clones: ['Dunbar', 'Dunbar Furniture Company', 'DUNBAR', 'dunbar'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630645a930aa77be920f607d',
		brand: 'Ebern Designs',
		link: 'https://storage.googleapis.com/kashew_brands/default_name222.webp',
		clones: ['Ebern Designs', 'EBERN DESIGNS', 'ebern designs'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630645e530aa77be920f6080',
		brand: 'eCowhides',
		link: 'https://storage.googleapis.com/kashew_brands/rYPw8aw3UfAV.webp',
		clones: ['eCowhides', 'ECOWHIDES', 'ecowhides'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306461a30aa77be920f6083',
		brand: 'EVGA',
		link: 'https://storage.googleapis.com/kashew_brands/2500px-EVGA_Logo.svg.png',
		clones: ['EVGA', 'evga'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306464d30aa77be920f608e',
		brand: 'Fitz and Floyd',
		link: 'https://storage.googleapis.com/kashew_brands/fitz-and-floyd.jpeg',
		clones: ['Fitz and Floyd', 'FITZ AND FLOYD', 'fitz and floyd'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630646c430aa77be920f60a1',
		brand: 'Fully',
		link: 'https://storage.googleapis.com/kashew_brands/98fbcd45-219c-4c31-848d-f43b78181a91.__CR0%2C0%2C500%2C250_PT0_SX350_V1___.jpg',
		clones: ['Fully', 'Fully / Jarvis', 'Jarvis', 'FULLY', 'fully'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306474130aa77be920f60b4',
		brand: 'George Oliver',
		link: 'https://storage.googleapis.com/kashew_brands/1550697932183%20(1).jpeg',
		clones: ['George Oliver', 'GEORGE OLIVER', 'george oliver'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306478b30aa77be920f60b7',
		brand: 'Global Views',
		link: 'https://storage.googleapis.com/kashew_brands/208859-global-views-logo.png',
		clones: ['Global Views', 'GLOBAL VIEWS', 'global views'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630647cb30aa77be920f60c2',
		brand: 'GoPlus ',
		link: 'https://storage.googleapis.com/kashew_brands/cc97a7d0cc677a8a4492507b5cebe889.w400.h400._CR0%2C0%2C400%2C400_SX200_.jpeg',
		clones: ['GoPlus', 'GoPlus ', 'GOPLUS ', 'goplus '],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630647f730aa77be920f60c5',
		brand: 'Gridmann',
		link: 'https://storage.googleapis.com/kashew_brands/GRIDMAN-LOGO-LG.webp',
		clones: ['Gridmann', 'GRIDMANN', 'gridmann'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306484130aa77be920f60c8',
		brand: 'Hans Andersen',
		link: 'https://storage.googleapis.com/kashew_brands/hca%20silhuet.png',
		clones: ['Hans Andersen', 'HANS ANDERSEN', 'hans andersen'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306486f30aa77be920f60cb',
		brand: 'Havertys',
		link: 'https://storage.googleapis.com/kashew_brands/haverty-furniture-logo-908634A5FE-seeklogo.com.png',
		clones: ['Havertys', 'HAVERTYS', 'havertys'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630649384eefedd2138f01c1',
		brand: 'Sony',
		link: 'https://storage.googleapis.com/kashew_brands/Sony-Logo.png',
		clones: ['Sony', 'SONY', 'sony'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '6306495c4eefedd2138f01c4',
		brand: 'Sonos',
		link: 'https://storage.googleapis.com/kashew_brands/Sonos_2015-Logo.png',
		clones: ['Sonos', 'SONOS', 'sonos'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630649984eefedd2138f01ca',
		brand: 'Zenbooth',
		link: 'https://storage.googleapis.com/kashew_brands/Zenbooth_Logo_-_Trademark-removebg-preview.png',
		clones: ['Zenbooth', 'ZENBOOTH', 'zenbooth'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630649b74eefedd2138f01cd',
		brand: 'Amazon',
		link: 'https://storage.googleapis.com/kashew_brands/Amazon_logo.svg.png',
		clones: ['Amazon', 'AMAZON', 'amazon'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630649e44eefedd2138f01d0',
		brand: 'Canon',
		link: 'https://storage.googleapis.com/kashew_brands/Canon_logo_vector.png',
		clones: ['Canon', 'Canon MX340', 'CANON', 'canon'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: '630c7cc37e4da7f2818f8e7d',
		brand: 'Haworth',
		link: 'https://www.haworth.com/content/dam/haworth-com/global/Haworth_Logo_Black.png',
		clones: ['Haworth', 'HAWORTH', 'haworth'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: 'unodue3',
		brand: 'Zuo Modern',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/zuo_modern.svg',
		clones: ['Zuo', 'zuo modern', 'modern zuo'],
		__v: 0,
	},
	{
		ranking: 0,
		_id: 'unodue3quatro',
		brand: 'de Sede',
		link: 'https://ik.imagekit.io/kuj9zqlrx0or/Static/brand_landing_page/logos/de_sede.svg',
		clones: ['de sede', 'De Sede'],
		__v: 0,
	},
];

export default brands;
